<template>
  <div>
    <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :on-cancel="onCancel"
                 :is-full-page="fullPage"></loading>
    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition" @keydown.esc="$emit('excEmit')">
      <v-card elevation="0" flat tile width="100%" height="100%">
        <v-toolbar dark color="secondary" flat tile>Request a Demo
          <v-spacer/>
          <v-btn icon dark @click="dialog = false">
            <v-icon @click="closeModal">{{mdiClose}}</v-icon>
          </v-btn>
        </v-toolbar>

          <div class="email-input-container" style="position: relative;text-align: center;">
              <br>
              <div class="demoField">
                  <div class="form-group">
                      <v-text-field filled background-color="#d4d4d4" v-model="demoEmail" clearable flat height="55" solo placeholder="Email Address"/>
                      <span style="color:red" v-html="demoEmailError"></span>
                  </div>
                  <div class="form-group">
                      <v-text-field filled background-color="#d4d4d4" v-model="name" clearable flat height="55" solo placeholder="Name"/>
                      <span style="color:red" v-html="nameError"></span>
                  </div>
                  <div class="form-group">
                      <v-text-field filled background-color="#d4d4d4" v-model="phoneNumber" clearable flat height="55" solo placeholder="Phone Number"/>
                      <span style="color:red" v-html="phoneNumberError"></span>
                  </div>
                  <div class="form-group">
                      <v-text-field filled background-color="#d4d4d4" v-model="company" clearable flat height="55" solo placeholder="Company Name"/>
                      <span style="color:red" v-html="companyError"></span>
                  </div>
                  <div class="form-group">
                      <v-text-field filled background-color="#d4d4d4" v-model="heardUs" clearable flat height="55" solo placeholder="How did you hear about us?"/>
                      <span style="color:red; text-align: left;" v-html="heardUsError"></span>
                  </div>
                  <div class="form-group" style="margin-bottom: 20px;">
                    <vue-recaptcha :sitekey="siteKeyVariable" :loadRecaptchaScript="true" @verify="markRecaptchaAsVerified"></vue-recaptcha>
                    <span style="color:red; margin-top: 0px;" v-html="pleaseTickRecaptchaMessage"></span>
                  </div>
                  <v-btn height="56" class="get-demo-btn" tile color="primary" @click="getDemo()">
                      Get Demo Now
                  </v-btn>
              </div>
          </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
    .video-container {
        height: calc(100% - 66px);
        width: 100%;
    }
    .form-group span{
      text-align: left;
      display: block;
      margin: -15px 10px 20px;
      font-size: 12px;
    }

    .demoField{
        width: 30%;
        margin: auto;
    }

    @media only screen and (max-width: 600px) {
        .demoField{
            width: 90%;
        }
    }
</style>

<script>
// @ is an alias to /src
import {mdiClose} from '@mdi/js';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueRecaptcha from 'vue-recaptcha';

export default {
  data() {
    return {
        mdiClose: mdiClose,
        dialog: true,
        name: '',
        phoneNumber: '',
        company: '',
        demoEmailError: '',
        nameError: '',
        phoneNumberError: '',
        companyError: '',
        heardUs: '',
        heardUsError: '',
        isLoading: false,
        fullPage: true,
        recaptchaVerified: false,
        pleaseTickRecaptchaMessage: '',
        captchaToken: ''
    }
  },
  components: {
    Loading, VueRecaptcha
  },
  props: {
    url: {
      type: String,
      default: ""
    },
    demoEmail:{
      type: String,
      default: ""
    }
  },
  computed:{
    siteKeyVariable(){
          return process.env.VUE_APP_CAPTCHA_SITE_KEY
    }
  },  
  methods: {
    markRecaptchaAsVerified(response) {
      this.captchaToken = response
      this.pleaseTickRecaptchaMessage = '';
      this.recaptchaVerified = true;
    },
    closeModal() {
      this.$emit('getDemoModalClose', false);
    },
    getDemo(){
        this.isLoading = true
        this.demoEmailError = '';
        this.nameError = '';
        this.phoneNumberError = '';
        this.companyError = '';
        this.heardUsError = '';
        var errorStatus = false;
        if(this.demoEmail == ''){
            this.demoEmailError = "Enter your email address.";
            errorStatus = true;
        }else{
          if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.demoEmail)){
            errorStatus = false;
          }else{
            this.demoEmailError = "Enter your valid email.";
            errorStatus = true;
          }
        }
        if(this.name == ''){
            this.nameError = "Enter your name.";
            errorStatus = true;
        }
        if(this.phoneNumber == ''){
            this.phoneNumberError = "Enter your phone number.";
            errorStatus = true;
        }
        if(this.company == ''){
            this.companyError = "Enter your company Name.";
            errorStatus = true;
        }
        if(this.heardUs == ''){
            this.heardUsError = "This field is required.";
            errorStatus = true;
        }
        if (!this.recaptchaVerified) {
          this.pleaseTickRecaptchaMessage = 'Please tick recaptcha.';
          errorStatus = true;
        }
        if(errorStatus){
          this.isLoading = false
            return;
        }

       

        let formData = new FormData();
        formData.append('email', this.demoEmail);
        formData.append('name', this.name);
        formData.append('contact_no', this.phoneNumber);
        formData.append('company', this.company);
        formData.append('heard_us', this.heardUs);
        formData.append('subject', 'New Demo Request.');
        formData.append('message',  this.demoEmail + ' has requested a demo.');
        formData.append('to', 'info@recordtime.com.au');
        formData.append('g_recaptcha_response', this.captchaToken);

                
        axios.post('https://v1.api.recordtimeapp.com.au/api/rt-frontend/send/mail',formData).then((res) => {
          if(res.data.status){
            this.demoEmailSuccess = res.data.message;
            this.demoEmail = '';
            this.name = '';
            this.phoneNumber = '';
            this.company = '';
            this.heardUs = '';
            this.$emit('demoResponse', res.data.message);
            this.closeModal();
            this.isLoading = false
            
          }else{
            this.demoEmailError = res.data.message;
            this.isLoading = false
            this.closeModal();
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.closeModal();
          console.log(error);
        })
        this.captchaToken = '';
        VueRecaptcha.reset();
    }
  }
}
</script>
